<template>
  <div>
    <div v-if="month.hours">
      {{month.hours}} <label-theme-component class="small" v-if="month.type">{{monthType}}</label-theme-component>
    </div>
    <div v-else>
      <octo-icon class="small" icon="wrong"/>
    </div>
  </div>
</template>

<script>
import OctoIcon from "@/components/octo-icon/OctoIcon";
import {Tooltip} from "element-ui";
import LabelThemeComponent from "@/components/LabelThemeComponent";

export default {
  name: "TimesheetDatatableSummary",
  components: {LabelThemeComponent, OctoIcon, [Tooltip.name]: Tooltip},
  props: {
    month: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    monthType: function () {
      return this.month.type === 'part-time' ? 'PT' : 'FT';
    }
  }
}
</script>

<style scoped lang="scss">
</style>
