<template>
  <div class="row">
    <div class="col-md-12">
      <timesheet-datatable/>
    </div>
  </div>
</template>

<script>
import TimesheetDatatable from "@/pages/CallCenter/datatables/TimesheetDatatable";
export default {
  name: "TimesheetPage",
  components: {TimesheetDatatable}
}
</script>

<style scoped>

</style>
