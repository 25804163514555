<template>
  <div class="row">

    <div class="col-12">
      <octo-table
        :action="endpoints.DATATABLES.callCenterTimesheets"
        :fields="fields"
        :filters="filters"
        :show-filters="true"
        :show-query-input="false"
        :show-id="false"
        ref="octoTable"
        name="TimesheetDataTable"
        @onResetFilters="resetFilters"
      >

        <template v-slot:name="data">
          <div class="text-uppercase text-truncate">
            {{ users[data.row.userId] | optional('firstname') }}
            {{ users[data.row.userId] | optional('lastname') }}
          </div>
        </template>

        <template v-slot:jan="data">
          <timesheet-datatable-summary :month="data.row[1]"/>
        </template>

        <template v-slot:feb="data">
          <timesheet-datatable-summary :month="data.row[2]"/>
        </template>

        <template v-slot:mar="data">
          <timesheet-datatable-summary :month="data.row[3]"/>
        </template>

        <template v-slot:apr="data">
          <timesheet-datatable-summary :month="data.row[4]"/>
        </template>

        <template v-slot:may="data">
          <timesheet-datatable-summary :month="data.row[5]"/>
        </template>

        <template v-slot:jun="data">
          <timesheet-datatable-summary :month="data.row[6]"/>
        </template>

        <template v-slot:jul="data">
          <timesheet-datatable-summary :month="data.row[7]"/>
        </template>

        <template v-slot:aug="data">
          <timesheet-datatable-summary :month="data.row[8]"/>
        </template>

        <template v-slot:sep="data">
          <timesheet-datatable-summary :month="data.row[9]"/>
        </template>

        <template v-slot:oct="data">
          <timesheet-datatable-summary :month="data.row[10]"/>
        </template>

        <template v-slot:nov="data">
          <timesheet-datatable-summary :month="data.row[11]"/>
        </template>

        <template v-slot:dic="data">
          <timesheet-datatable-summary :month="data.row[12]"/>
        </template>

        <template v-slot:actions="data">
          <div class="d-flex justify-content-end">
              <base-button
                icon
                class="mx-1"
                size="sm"
                link
                @click="$router.push({
                  name: 'call-center.timesheet.operator.yearly',
                  params: {id : data.row.userId, year: filters.year },
                })"
              >
                <octo-icon icon="right-arrow"/>
              </base-button>
          </div>
        </template>

        <template slot="custom-filters">
          <div class="col-12 col-md-4">
            <base-input :label="$t('common.select_year')" class="flex-grow-1">
              <el-date-picker
                type="year"
                v-model="date"
                :placeholder="$t('common.select_year')"
                @change="(date) => filters.year = date.getFullYear()"
              ></el-date-picker>
            </base-input>
          </div>
        </template>
      </octo-table>
    </div>
  </div>
</template>

<script>
import OctoTable from "@/components/octo-table/OctoTable";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import {endpoints} from "@/routes/endpoints";
import TimesheetDatatableSummary from "../OperatorComponents/TimesheetDatatableSummary";
import {DatePicker} from 'element-ui';
import {mapGetters} from "vuex";

export default {
  name: "TimesheetDatatable",
  components: {TimesheetDatatableSummary, OctoIcon, OctoTable, [DatePicker.name]: DatePicker},
  data() {
    return {
      date: this.$moment(),
      endpoints: endpoints,
      filters: {
        year: this.$moment().year()
      },
      fields: [
        {prop: 'name', label: 'name', align: 'left', slot: true, minWidth: 200, fixed: true},
        {prop: 'jan', label: 'jan', width: 75, align: 'center', slot: true,},
        {prop: 'feb', label: 'feb', width: 75, align: 'center', slot: true,},
        {prop: 'mar', label: 'mar', width: 75, align: 'center', slot: true,},
        {prop: 'apr', label: 'apr', width: 75, align: 'center', slot: true,},
        {prop: 'may', label: 'may', width: 75, align: 'center', slot: true,},
        {prop: 'jun', label: 'jun', width: 75, align: 'center', slot: true,},
        {prop: 'jul', label: 'jul', width: 75, align: 'center', slot: true,},
        {prop: 'aug', label: 'aug', width: 75, align: 'center', slot: true,},
        {prop: 'sep', label: 'sep', width: 75, align: 'center', slot: true,},
        {prop: 'oct', label: 'oct', width: 75, align: 'center', slot: true,},
        {prop: 'nov', label: 'nov', width: 75, align: 'center', slot: true,},
        {prop: 'dic', label: 'dic', width: 75, align: 'center', slot: true,},
        {prop: 'actions', label: 'actions', width: 80, slot: true, fixed: 'right'},
      ]
    }
  },
  computed: {
    ...mapGetters({
      users: 'common/users'
    })
  },
  methods: {

    resetFilters: function () {
      this.date = this.$moment();
      this.filters = {
        year: this.$moment().year()
      };
    },
  },

}
</script>

<style scoped>

</style>
